<template lang="html">

  <section class="home">
    <div class="banner">
      <div class="container">
        <h1>Zona Profesionales</h1>
        <p>Espacio privado para profesionales del sector de la hostelería, con descuentos exclusivos y personalizados.
          <br>

        Regístrate y nuestro equipo te llamará para darte acceso y detallarte todas las ventajas de nuestra zona profesionales.
        </p>
        <router-link to="/#registro" class="btn-white-line"  @click="scrollSection('registro')">REGÍSTRATE</router-link>
      </div>
    </div>
    <div class="container ventajas">
      <h2 class="section-title">Descubre las Ventajas</h2>
      <p class="text-center">PROFESIONALES</p>
      <div class="grid-3">
        <div class="grid">
          <p class="dest">
          <span>%</span>
          </p>
          <strong>Gasta menos</strong>
          <p>Promociones exclusivas y personalizadas para cada profesional.</p>
        </div>
        <div class="grid">
          <p class="dest">
            0<span>€</span>
          </p>
          <strong>Envío gratuito</strong>
          <p>Con tu cuenta profesional, recibirás todos tus pedidos sin gastos de envío.</p>
        </div>
        <div class="grid">
          <p class="dest">
            24<span>h</span>
          </p>
          <strong>Envíos Express</strong>
          <p>Llegamos siempre a tiempo. Envío de pedidos en menos de 24h a cualquier punto de España.</p>
        </div>
      </div>
    </div>
    <div class="form-register">
      <div class="container">
        <h2>
          Registro Profesionales
        </h2>
        <div class="grid-form">
          <p class="intro"  id="registro"> </p>
          <formRegister></formRegister>
        </div>
      </div>
    </div>
    <div class="container productos">
      <h2 class="section-title">Descubre Aldonza</h2>
      <p class="text-center">NUESTROS PRODUCTOS</p>
    </div>
    <div class="category-tree">
      <ul class="category-tree__list list-group">
        <li class="category-tree__child list-group-item">
          <ul class="category-tree__list">
            <li class="category-tree__item">
              <div class="category-tree__item__header">
                <a class="category-tree__item__link" target="_blank" href="https://tienda.aldonzagourmet.com/index.php?id_category=3&amp;controller=category">Vinos</a>
              </div>
            </li>
            <li class="category-tree__item">
              <div class="category-tree__item__header">
                <a class="category-tree__item__link" target="_blank" href="https://tienda.aldonzagourmet.com/index.php?id_category=4&amp;controller=category">Aceite</a>
              </div>
            </li>
            <li class="category-tree__item">
              <div class="category-tree__item__header">
                <a class="category-tree__item__link" target="_blank" href="https://tienda.aldonzagourmet.com/index.php?id_category=5&amp;controller=category">Azafrán</a>
              </div>
            </li>
            <li class="category-tree__item">
              <div class="category-tree__item__header">
                <a class="category-tree__item__link" target="_blank" href="https://tienda.aldonzagourmet.com/index.php?id_category=6&amp;controller=category">Cava</a>
              </div>
            </li>
            <li class="category-tree__item">
              <div class="category-tree__item__header">
                <a class="category-tree__item__link" target="_blank" href="https://tienda.aldonzagourmet.com/index.php?id_category=7&amp;controller=category">Queso</a>
              </div>
            </li>
            <li class="category-tree__item">
              <div class="category-tree__item__header">
                <a class="category-tree__item__link" target="_blank" href="https://tienda.aldonzagourmet.com/index.php?id_category=8&amp;controller=category">Miscelánea</a>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
</template>

<script lang="js">
  import formRegister from "@/components/form-register.vue";
  export default {
    name: 'home',
    props: [],
    components: {
      formRegister
    },
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      scrollSection: function (params) {
        setTimeout(function () {
          document.getElementById(params).scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        }, 200);

      }
    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .home {}
</style>