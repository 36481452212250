<template lang="html">
   <section class="form enviado" v-if="enviado==true">
    <h1>Su solicitud ha sido recibida correctamente. Procedemos a validar sus datos y en breve contactaremos con usted para habilitarle el acceso a su cuenta profesional. 
</h1>
   </section>
  <section class="form" v-if="enviado==false">
   
    <div class="content-form">
      <div class="container-input">
      <label for="nombre">Nombre</label>
      <div class="input-box">
        <input type="text" placeholder="Nombre" v-model="state.nombre">
        <span v-if="v$.nombre.$error">
          {{ v$.nombre.$errors[0].$message }}
        </span>
      </div>
    </div>
    <div class="container-input">
      <label for="apellidos">Apellidos</label>
      <div class="input-box">
        <input type="text" placeholder="Apellidos" v-model="state.apellidos">
        <span v-if="v$.apellidos.$error">
          {{ v$.apellidos.$errors[0].$message }}
        </span>
      </div>
    </div>    
    
    <div class="container-input">
      <label for="empresa">Empresa</label>
      <div class="input-box">
        <input type="text" placeholder="Empresa" v-model="state.empresa">
        <span v-if="v$.empresa.$error">
          {{ v$.empresa.$errors[0].$message }}
        </span>
      </div>
    </div>
    <div class="container-input">
      <label for="cif">DNI/NIF/NIE/CIF</label>
      <div class="input-box">
        <input type="text" placeholder="DNI/NIF/NIE/CIF" v-model="state.cif">
        <span v-if="v$.cif.$error">
          {{ v$.cif.$errors[0].$message }}
        </span>
      </div>
    </div>
    <div class="container-input">
      <label for="email">Email</label>
      <div class="input-box">
        <input type="text" placeholder="Email" v-model="state.email">
        <span v-if="v$.email.$error">
          {{ v$.email.$errors[0].$message }}
        </span>
      </div>
    </div>
    <div class="container-input">
      <label for="telefono">Teléfono</label>
      <div class="input-box">
        <input type="text" placeholder="Teléfono" v-model="state.telefono">
        <span v-if="v$.telefono.$error">
          {{ v$.telefono.$errors[0].$message }}
        </span>
      </div>
    </div>
    <div class="container-input">
      <label for="direccion">Dirección</label>
      <div class="input-box">
        <input type="text" placeholder="Dirección" v-model="state.direccion">
        <span v-if="v$.direccion.$error">
          {{ v$.direccion.$errors[0].$message }}
        </span>
      </div>
    </div>
    <div class="checkbox-styled w-100">
      <input id="rgpd" nombre="rgpd" type="checkbox" v-model="state.rgpd">

      <label for="rgpd">
        He leido y acepto la
        <a href="https://tienda.aldonzagourmet.com/index.php?id_cms=7&controller=cms" target="_blank"
          title="Política de privacidad">Política de Privacidad</a><br>
        <span class="mss-error" v-if="v$.rgpd.$error">
          {{ v$.rgpd.$errors[0].$message }}
        </span>
      </label>

    </div>
    <div class="container-button">
      <button class="btn-white-line" @click="submitForm()">ENVIAR</button>
    </div>
    </div>

    <div class="content-form">
      <p><strong>Información básica sobre protección de datos</strong></p>
      <p><strong>Responsable del tratamiento</strong></p>
      <p>ALDONZA GOURMET S.A.U.</p>
      <p><strong>Dirección del responsable</strong></p>
      <p> Parque Empresarial Ajusa Nº1 – Crta. CM 332, Km 2,2, CP 02006, Albacete (España)</p>
      <p><strong>Finalidad</strong></p>
      <p>Sus datos serán usados para poder atender sus solicitudes y prestarle nuestros servicios.</p>
      <p><strong>Publicidad</strong></p>
      <p>Solo le enviaremos publicidad con su autorización previa, que podrá facilitarnos mediante la casilla correspondiente establecida al efecto.</p>
      <p><strong>Legitimación</strong></p>
      <p> Únicamente trataremos sus datos con su consentimiento previo, que podrá facilitarnos mediante la casilla correspondiente establecida al efecto.</p>
      <p><strong>Destinatarios</strong></p>
      <p> Con carácter general, sólo el personal de nuestra entidad que esté debidamente autorizado podrá tener conocimiento de la información que le pedimos.</p>
      <p><strong>Derechos</strong></p>
      <p> Tiene derecho a saber qué información tenemos sobre usted, corregirla y eliminarla, tal y como se explica en la información adicional disponible en nuestra página web.</p>
      <p><strong>Información adicional</strong></p>
      <p> Más información en el apartado <u><a href="https://tienda.aldonzagourmet.com/index.php?id_cms=9&controller=cms" >SUS DATOS SEGUROS</a></u> de nuestra página web.</p>
      </div>
    
  </section>

</template>

<script lang="js">
import useValidate from "@vuelidate/core";
import { reactive, computed } from "vue";
import { required, email, helpers } from "@vuelidate/validators";
const cifCheck = helpers.regex(/^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/);
const requiredMsg = helpers.withMessage('Este campo es obligatorio', required);
const telefonoCheck = helpers.regex(/^([+][0-9]{2})?[0-9]{7,9}$/);
import axios from "axios";
export default {
  name: 'form',
  props: [],
  data:()=>({
      enviado: false,
  }),
  mounted() {

  },

  setup() {
    const state = reactive({
      nombre: '',
      apellidos: '',
      direccion: '',
      empresa: '',
      cif: '',
      email: '',
      telefono: '',
      rgpd: '',
    })
    const rules = computed(() => {
      return {

        nombre: {
          required: requiredMsg,
        },
        apellidos: {
          required: requiredMsg,
        },
        direccion: {
          required: requiredMsg,
        },
        empresa: {
          required: requiredMsg,
        },
        cif: {
          required: requiredMsg,
        },
        telefono: {
          required: requiredMsg,
          telefonoCheck: helpers.withMessage('Por favor introduzca un teléfono válido', telefonoCheck)
        },
        email: {
          required: requiredMsg,
          email: helpers.withMessage('Por favor introduzca un email válido', email)
        },
        rgpd: {
          required: requiredMsg,
        },
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$,
      // storeForm
      // storeContent
    }
  },

  methods: {
    async submitForm() {
      var msg = ""
      var isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        msg = 'Se envia';
        this.enviado=true;
        axios.post('https://dmb.doubledot.es/api/auto_contact/aldonza', this.state).then(r => {
          //https://dmb.doubledot.es/api/auto_contact/aldonza
          this.state = {

            'nombre': '',
            'apellidos': '',
            'direccion': '',

            'empresa': '',
            'cif': '',
            'email': '',
            'telefono': '',
            'rgpd': '',
          };
        


        })
        // var promise = axios.get('https://api.plos.org/search?q=title:DNA');
        // promise.then(function (result) { console.log(result) });
      }
      else {
        msg = 'No Se envia'
      }
      return msg

    },
    // onSubmit() {
    //   var formElement = document.getElementById("formContact");
    //   var formData = new FormData(formElement);
    //   storeForm.formContact(this.formData).then((response) => {
    //   })
    // },

  }
}


</script>

<style scoped lang="scss">
.form {}
</style>